import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import SEO from "@/components/SEO"
import { withLanguage } from "@/utils/i18n"
import Layout from "@/components/Layout"
import { graphql } from "gatsby"
import CentreContainer from "@/components/Container"
import FullWidthImage from "@/components/Swiper/FullWidthImage"
import RelatedCard from "@/components/Swiper/RelatedCard"
import { defendantColor, statusPriority } from "@/utils/defendant"
import { useBasicStyle } from "@/theme"
import { StickyCard } from "@/components/StickyCard"
import Legend from "@/components/Legend"
import marked from "marked"
import Back from "@/components/Back"
import DefendantDots from "@/DefendantDots"
import Loader from "@/components/Loader"
import { trackCustomEvent } from "@/utils/ga"

const IncidentTemplate = ({
  pageContext: { localePath, allIncidents },
  data: {
    cms: { incident },
  },
}) => {
  const { i18n, t } = useTranslation()
  const classes = useBasicStyle()

  const [thisIncident] = incident
  const incidentIndex = allIncidents.findIndex(c => c.id === thisIncident.id)
  const otherIncidents = [
    ...allIncidents.slice(incidentIndex + 1),
    ...allIncidents.slice(0, incidentIndex),
  ]

  const [selectedLegendId, setSelectedLegendId] = useState(-1)

  const [defendants, setDefendants] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      let data = await fetch(
        `${window.location.origin}/data/incidents/${thisIncident.id}.json`
      ).then(responses => responses.json())

      setDefendants(data.defendants)
      setIsLoading(false)
    }

    fetchData()
  }, [])

  const selectedDefendants = defendants
    .map(defendent => ({
      id: defendent.person.id,
      ...defendent,
      status: {
        ...defendent.status,
        ...defendantColor.case[`status${defendent.status.id || 0}`],
      },
    }))
    .filter(
      d => selectedLegendId == -1 || [selectedLegendId].includes(d.status.id)
    )
    .sort((a, b) => {
      if (!a.status || !b.status) {
        return -1
      }
      return (
        statusPriority.findIndex(s => s === a.status.id) -
        statusPriority.findIndex(s => s === b.status.id)
      )
    })

  return (
    <div>
      <Layout localePath={localePath}>
        <SEO
          title={withLanguage(i18n, thisIncident, "name")}
          description={withLanguage(i18n, thisIncident, "desc")}
        />
        <div>
          <FullWidthImage
            images={[...Array(5).keys()]
              .map(i => ({
                url:
                  (thisIncident[`image_${i + 1}_url`] &&
                    thisIncident[`image_${i + 1}_url`].replace(
                      "raw",
                      "large"
                    )) ||
                  null,
                align: thisIncident[`image_${i + 1}_align`],
                alignMobile: thisIncident[`image_${i + 1}_align_mobile`],
              }))
              .filter(a => a.url)}
          >
            <div className="header">
              <div className="header__Name">
                {withLanguage(i18n, thisIncident, "name")}
              </div>
              <div className="header__DefendantCount">
                {t("common.defendant_count", { count: defendants.length })}
              </div>
            </div>
          </FullWidthImage>
          <CentreContainer>
            <Back />
          </CentreContainer>
          <CentreContainer>
            <div
              className={classes.descriptionWrapper}
              dangerouslySetInnerHTML={{
                __html: marked(withLanguage(i18n, thisIncident, "desc")),
              }}
            />
          </CentreContainer>
          <div className={classes.legendWrapper}>
            <Legend
              filterable={true}
              type="incidents"
              activeId={selectedLegendId}
              handleClick={(clickedIndex, items) => {
                setSelectedLegendId(
                  items[clickedIndex].id == selectedLegendId
                    ? -1
                    : items[clickedIndex].id
                )
                trackCustomEvent("Click", {
                  event_category: "Legend",
                  event_label: `${withLanguage(i18n, thisIncident, "name")} - ${
                    items[clickedIndex].title
                  }`,
                })
              }}
            />
          </div>
          <CentreContainer>
            {isLoading ? (
              <Loader />
            ) : (
              <DefendantDots
                selectedDefendants={selectedDefendants}
                context="status"
              />
            )}
          </CentreContainer>
          <div className={classes.fullWidthSecondary}>
            <CentreContainer padding={0}>
              <RelatedCard
                enableImage={true}
                items={otherIncidents.map(incident => ({
                  id: incident.id,
                  name: withLanguage(i18n, incident, "name"),
                  imgUrl: incident.image_url,
                  to: {
                    page: "template-incident",
                    lang: i18n.language,
                    obj: incident,
                  },
                  countText: t("common.defendant_count", {
                    count: incident.defendantCount,
                  }),
                }))}
              />
            </CentreContainer>
          </div>
          <StickyCard />
        </div>
      </Layout>
    </div>
  )
}

export default IncidentTemplate

export const query = graphql`
  query IncidentQuery($incidentId: Int!) {
    cms {
      incident: incidents(where: { id: { _eq: $incidentId } }) {
        ...IncidentBasicInfo
        desc_zh
        desc_en
        image_1_url: image_url
        image_2_url
        image_3_url
        image_4_url
        image_5_url
        image_1_align: image_align
        image_1_align_mobile: image_align_mobile
        image_2_align
        image_2_align_mobile
        image_3_align
        image_3_align_mobile
        image_4_align
        image_4_align_mobile
        image_5_align
        image_5_align_mobile
      }
      defendants: people(
        where: {
          case_person: { case: { incident: { id: { _eq: $incidentId } } } }
        }
      ) {
        id
        initial_zh
        initial_en
        hide_name
        gender
        image_url
        case_person(
          where: { case: { incident: { id: { _eq: $incidentId } } } }
        ) {
          arrest_age
          occupation {
            type_zh
            type_en
          }
          status {
            id
            type_zh
            type_en
          }
        }
      }
      case_person_status {
        id
        type_zh
        type_en
      }
    }
  }
`
